import { fetchApi } from "@shared/lib";
import { toast } from "sonner";

export const fetchAvailablePlayers = async (requestBody: Api.Request.AvailablePlayers) => {
  try {
    const response = await fetchApi<Api.AvailablePlayers>(`/api/contests/participants/refresh`, {
      method: "POST",
      body: JSON.stringify(requestBody),
    });

    return response.data.availablePlayers;
  } catch (error) {
    toast.error("There was an error refreshing players. Please try again in a few minutes.");
    throw error;
  }
};

type AllowedMetricNames = "user_flow.intro_guide_open";
export const sendMetricsRequest = async (metricName: AllowedMetricNames) => {
  fetch("/api/metric", {
    method: "POST",
    body: JSON.stringify(metricName),
  });
};

export const clearChatHistory = async () => {
  const confirmed = confirm("Are you sure you want to clear your chat history?");

  if (!confirmed) {
    return;
  }

  const response = await fetch(`/api/chat/`, { method: "DELETE" });
  const data = await response.json();
  if (data.success) {
    toast.success("All messages have been cleared.");
    window.location.reload();
  } else {
    toast.error("Failed to clear messages.");
  }
};
