import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { Contest, DfsStack, Exposure, Participant, ContestParticipants, OwnershipBonuses, Team } from "../types";

export type LineupSettingsState = {
  league: SportLeague | "";
  availableLeagues: SportLeague[];
  platform: DfsPlatform | "";
  contests: Contest[];
  selectedContest?: Contest;
  contestParticipants: ContestParticipants;
  lineupAmount: number;
  blankCard: boolean;
  excludedTeams?: Array<Team["abbreviation"]>;
  lockedPlayers?: Record<number, Participant["id"] | undefined>;
  excludedPlayers?: Array<Participant["id"]>;
  stack: DfsStack;
  autoOwnershipBonusType?: "global" | "position" | "off";
  autoOwnershipBonuses?: OwnershipBonuses[];
  excludedInOnlyStartersMode?: boolean;
  excludeOutPlayers?: boolean;
  excludeGtdPlayers?: boolean;
  excludeOpposingDefense?: boolean;
  minPlayerFantasyPoints?: number;
  projectionProviders: string[];
  exposure: Exposure;
  cyclone: {
    isCyclone: boolean;
    cycloneStackAttack: boolean;
    cycloneStackTeams: string[];
  };
};

type LineupSettingsAction = {
  setContestLeague: (league: LineupSettingsState["league"]) => void;
  setContests: (contests: LineupSettingsState["contests"]) => void;
  setSelectedContest: (selectedContest: LineupSettingsState["selectedContest"]) => void;
  setContestParticipants: (participants: LineupSettingsState["contestParticipants"]) => void;
  setAvailableLeagues: (availableLeagues: LineupSettingsState["availableLeagues"]) => void;
  setContestPlatform: (platform: LineupSettingsState["platform"]) => void;
  setLineupAmount: (lineupAmount: LineupSettingsState["lineupAmount"]) => void;
  setBlankCard: (blankCard: LineupSettingsState["blankCard"]) => void;

  setExcludedTeams: (excludedTeams: LineupSettingsState["excludedTeams"]) => void;
  setLockedPlayers: (lockedPlayers: LineupSettingsState["lockedPlayers"]) => void;
  setExcludedPlayers: (excludedPlayers: LineupSettingsState["excludedPlayers"]) => void;

  setStack: (stack: DfsStack) => void;

  //Powertools
  setAutoOwnershipBonusType: (autoOwnershipBonusType: LineupSettingsState["autoOwnershipBonusType"]) => void;
  setAutoOwnershipBonuses: (ownershipBonuses: OwnershipBonuses[]) => void;
  setExcludedInOnlyStartersMode: (excludedInOnlyStartersMode: boolean) => void;
  setExcludeOutPlayers: (excludeOutPlayers: boolean) => void;
  setExcludeGtdPlayers: (excludeGtdPlayers: boolean) => void;
  setExcludeOpposingDefense: (excludeOpposingDefense: boolean) => void;
  setMinPlayerFantasyPoints: (minPlayerFantasyPoints: number) => void;

  setProjectionProviders: (projectionProviders: LineupSettingsState["projectionProviders"]) => void;
  setCyclone: (cyclone: LineupSettingsState["cyclone"]) => void;
  setExposure: (exposure: LineupSettingsState["exposure"]) => void;
  setLineupSettings: (LineupSettings: Partial<LineupSettingsState>) => void;
  resetSettings: () => void;
  resetAdvancedSettings: () => void;
};

export const useLineupSettings = create<LineupSettingsState & LineupSettingsAction>()(
  devtools(
    persist(
      (set) => ({
        league: "",
        availableLeagues: [],
        platform: "",
        lineupAmount: 1,
        blankCard: false,
        stack: {
          stackTeam: "None",
          stackBonusType: "off",
          teamBonusPercentage: 0,
        },
        contests: [],
        selectedContest: undefined,
        contestParticipants: {
          teams: [],
          participants: [],
        },
        projectionProviders: ["Quarter4"],
        minPlayerFantasyPoints: 4,

        excludeOutPlayers: true,
        autoOwnershipBonusType: "off",

        cyclone: {
          isCyclone: false,
          cycloneStackAttack: false,
          cycloneStackTeams: [],
        },
        exposure: {
          maxExpSettings: {},
          minExpSettings: {},
          autoMaxExp: false,
          globalMaxExpPercentage: 1,
          autoMinExp: false,
          autoDiversityBonus: false,
          diversityBonusPercentage: 1,
          autoDiversityBonusPercentage: 1,
        },

        setContestLeague: (league) => set({ league }),
        setContests: (contests) => set({ contests }),
        setSelectedContest: (selectedContest) => set({ selectedContest }),
        setContestParticipants: (contestParticipants) => set({ contestParticipants }),
        setAvailableLeagues: (availableLeagues) => set({ availableLeagues }),
        setContestPlatform: (platform) => set({ platform }),
        setLineupAmount: (lineupAmount) => set({ lineupAmount }),
        setBlankCard: (blankCard) => set({ blankCard }),

        setExcludedTeams: (excludedTeams) => set({ excludedTeams }),
        setLockedPlayers: (lockedPlayers) => set({ lockedPlayers }),
        setExcludedPlayers: (excludedPlayers) => set({ excludedPlayers }),

        setCyclone: (cyclone) => set({ cyclone }),
        setStack: (stack) => set({ stack }),

        // Power Tools
        setAutoOwnershipBonusType: (autoOwnershipBonusType) => set({ autoOwnershipBonusType }),
        setAutoOwnershipBonuses: (autoOwnershipBonuses) => set({ autoOwnershipBonuses }),
        setExcludedInOnlyStartersMode: (excludedInOnlyStartersMode) => set({ excludedInOnlyStartersMode }),
        setExcludeOutPlayers: (excludeOutPlayers) => set({ excludeOutPlayers }),
        setExcludeGtdPlayers: (excludeGtdPlayers) => set({ excludeGtdPlayers }),
        setExcludeOpposingDefense: (excludeOpposingDefense) => set({ excludeOpposingDefense }),
        setMinPlayerFantasyPoints: (minPlayerFantasyPoints) => set({ minPlayerFantasyPoints }),

        setExposure: (exposure) => set({ exposure }),
        setProjectionProviders: (projectionProviders) => set({ projectionProviders }),
        setLineupSettings: (LineupSettings) => set(LineupSettings),
        resetSettings: () => {
          set({
            platform: "",
            contests: [],
            excludedPlayers: [],
            lockedPlayers: {},
            selectedContest: undefined,
            league: "",
            availableLeagues: [],
            blankCard: false,
            stack: {
              stackTeam: "None",
              stackBonusType: "off",
              teamBonusPercentage: 0,
            },
            projectionProviders: ["Quarter4"],
          });
        },
        resetAdvancedSettings: () => {
          set((state) => ({
            ...state,
            excludedPlayers: [],
            lockedPlayers: {},
            cyclone: {
              isCyclone: false,
              cycloneStackAttack: false,
              cycloneStackTeams: [],
            },
            exposure: {
              maxExpSettings: {},
              minExpSettings: {},
              autoMaxExp: false,
              globalMaxExpPercentage: 1,
              autoMinExp: false,
              globalMinExpPercentage: 1,
              autoDiversityBonus: false,
              diversityBonusPercentage: 1,
              autoDiversityBonusPercentage: 1,
            },
            stack: {
              stackTeam: "None",
              stackBonusType: "off",
              teamBonusPercentage: 0,
            },
            minPlayerFantasyPoints: 4,
            excludeOutPlayers: false,
            excludeGtdPlayers: undefined,
            excludeOpposingDefense: undefined,
            autoOwnershipBonusType: undefined,
          }));
        },
      }),
      {
        name: "heavyDfsProLineupSettings",
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { enabled: process.env.NODE_ENV === "development" }
  )
);
