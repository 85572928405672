import { fetchApi } from "@shared/lib";
import { toast } from "sonner";

export const fetchSavedLineups = async () => {
  try {
    const response = await fetchApi<Api.SavedLineups>("/api/lineups/save");
    return response.data;
  } catch (error) {
    toast.error("There was an error fetching saved lineups. Please try again in a few minutes.");
    throw error;
  }
};

export const fetchSavedLineup = async (id: string) => {
  try {
    const response = await fetchApi<Api.SavedLineup>(`/api/lineups/save/${id}`);
    return response.data;
  } catch (error) {
    toast.error("There was an error fetching this lineup. Please try again in a few minutes.");
    throw error;
  }
}
