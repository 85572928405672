import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";
import { Lineup } from "../types";

export type GeneratedLineupState = {
  lastGenerationId?: string;
  lineupAmount: number;
  contestTime?: string;
  contestSuffix?: string;
  contestGamesAmount?: number;
  lineups: Array<Lineup>;
  currentLineup?: number;
};

type GeneratedLineupAction = {
  setLastGenerationId: (lastGenerationId: GeneratedLineupState["lastGenerationId"]) => void;
  setLineupAmount: (lineupAmount: GeneratedLineupState["lineupAmount"]) => void;
  setContestTime: (contestTime: GeneratedLineupState["contestTime"]) => void;
  setContestGamesAmount: (contestGamesAmount: GeneratedLineupState["contestGamesAmount"]) => void;
  setLineups: (lineups: GeneratedLineupState["lineups"]) => void;
  setCurrentLineup: (currentLineup: GeneratedLineupState["currentLineup"]) => void;
  resetGenerateLineup: () => void;
};

export const useGeneratedLineup = create<GeneratedLineupState & GeneratedLineupAction>()(
  devtools(
    persist(
      (set) => ({
        lineupAmount: 1,
        currentLineup: 1,
        lineups: [],

        setLastGenerationId: (lastGenerationId) => set({ lastGenerationId }),
        setLineupAmount: (lineupAmount) => set({ lineupAmount }),

        setContestTime: (contestTime) => set({ contestTime }),
        setContestGamesAmount: (contestGamesAmount) => set({ contestGamesAmount }),

        setLineups: (lineups) => set({ lineups }),
        setCurrentLineup: (currentLineup) => set({ currentLineup }),
        resetGenerateLineup: () => {
          set({
            contestTime: undefined,
            contestSuffix: undefined,
            contestGamesAmount: undefined,
            lineups: [],
            currentLineup: 1,
          });
        },
      }),
      {
        name: "heavyDfsProGeneratedLineup",
        storage: createJSONStorage(() => sessionStorage),
      }
    ),
    { enabled: process.env.NODE_ENV === "development" }
  )
);
