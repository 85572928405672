export function downloadCSV(data: string, filename: string) {
  const blob = new Blob([data], { type: "text/csv" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.style.display = "none";
  a.href = url;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  URL.revokeObjectURL(url);
}

export const formatCurrency = (value: number) => {
  const number = typeof value === "string" ? parseFloat(value) : value;

  if (isNaN(number)) {
    return "Invalid input";
  }

  const formattedNumber = number.toLocaleString("en-US");

  return `$${formattedNumber}`;
};

export const formatOwnership = (value?: number | string) => {
  if (value === undefined) {
    return "";
  }

  const number = typeof value === "string" ? parseFloat(value) : value;

  if (isNaN(number)) {
    return "";
  }

  const formattedNumber = number.toLocaleString("en-US", {
    minimumFractionDigits: number < 10 ? 1 : 0,
    maximumFractionDigits: number < 10 ? 1 : 0,
  });

  return `${formattedNumber}%`;
};

/**
 * formats date to standart American date format
 * @param date JS Date object
 */
export const formatDate = (date: Date) => {
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  const timeString = date.toLocaleTimeString("en-US", timeOptions).toLowerCase().replace(" ", "").replaceAll(":00", "");

  const dateOptions: Intl.DateTimeFormatOptions = {
    month: "numeric",
    day: "numeric",
  };
  const dateString = date.toLocaleDateString("en-US", dateOptions);

  return `${timeString} ${dateString}`;
};

export const scrollToBottom = (sectionRef: React.RefObject<HTMLDivElement>) => {
  sectionRef.current?.scrollTo({
    top: sectionRef.current.scrollHeight,
    behavior: "instant",
  });
};

export const orderProjectionProviders = (projectionProviders: string[]) => {
  const orderedProviders = ["quarter4", "baker", "rotowire"];

  return projectionProviders.sort((a, b) => {
    const indexA = orderedProviders.indexOf(a.toLowerCase());
    const indexB = orderedProviders.indexOf(b.toLowerCase());

    // Handle cases where elements are not in orderedProviders
    if (indexA === -1) return 1;
    if (indexB === -1) return -1;

    return indexA - indexB;
  });
};

export const getProjectionEngineLabel = (projectionEngine: string) => {
  if (projectionEngine.toLowerCase().includes("quarter4")) {
    return projectionEngine + " A.I.";
  }

  if (projectionEngine.toLowerCase().includes("baker")) {
    return projectionEngine + " A.I.";
  }

  return projectionEngine;
};

export const parsePositionFullName = (position: string, league: "" | SportLeague) => {
  const NBAPositions = new Map<string, string>([
    ["SG", "Shooting Guard"],
    ["PG", "Point Guard"],
    ["SF", "Small Forward"],
    ["PF", "Power Forward"],
    ["C", "Center"],
  ]);

  const NFLPositions = new Map<string, string>([
    ["QB", "Quarterback"],
    ["RB", "Running Back"],
    ["WR", "Wide Receiver"],
    ["TE", "Tight End"],
    ["DST", "Defense/Special Teams"],
  ]);

  const MLBPositions = new Map<string, string>([
    ["P", "Pitcher"],
    ["C", "Catcher"],
    ["1B", "First Baseman"],
    ["2B", "Second Baseman"],
    ["3B", "Third Baseman"],
    ["SS", "Shortstop"],
    ["LF", "Left Fielder"],
    ["CF", "Center Fielder"],
    ["RF", "Right Fielder"],
  ]);

  if (league === "NBA") {
    return NBAPositions.get(position);
  } else if (league === "NFL") {
    return NFLPositions.get(position);
  } else if (league === "MLB") {
    return MLBPositions.get(position);
  } else {
    return position;
  }
};

export const elipsis = (value: string, length: number) => {
  return value.length > length ? value.slice(0, length) + ".." : value;
};

/**
 * This function fetches the data from the specified url. It's expected that the data is in json format.
 * The function converts the json data to js object and returns it.
 * @param input data source url
 * @param init request init object
 */
export async function fetchAs<T>(input: RequestInfo, init?: RequestInit): Promise<T> {
  const response = await fetch(input, init);
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response.json();
}

/**
 * This function fetches the data from the specified url. It's expected that the data is in json format.
 * The function converts the json data to js object and returns it.
 * @param input data source url
 * @param init request init object
 */
export async function fetchApi<T>(input: RequestInfo, init?: RequestInit): Promise<Api.Response<T>> {
  const response = await fetch(input, init);
  if (!response.ok) {
    const error = await response.json();
    throw new Error(`${error.error} - ${error.detail}`);
  }
  return response.json();
}

/**
 * This function translates contest slot number to a slot name
 *
 * @example 1 -> QB, 2 -> RB, 3 -> WR, 4 -> TE, 5 -> FLEX, 6 -> DST
 * @param slotNumber slot number
 * @param slotNames array of slot names
 */
export const getSlotName = (slotNumber: number, slotNames: Array<{ slotNumber: number; slotName: string }>) => {
  return slotNames.find((slot) => slot.slotNumber === slotNumber)?.slotName;
};

export const getAvailableSlots = (
  lineupRequirements:
    | {
      slotNumber: number;
      allowedPositions: string[];
      slotName: string;
    }[]
    | undefined
) => {
  const combinedPositions: string[] = [];
  lineupRequirements?.forEach((slot) => {
    combinedPositions.push(...slot.allowedPositions);
  });
  return combinedPositions;
};

export const emptySlot = (slotName: string, slotNumber: number) => ({
  id: "",
  aslBaseId: 0,
  headshotUrl: "",
  position: "",
  slotNumber,
  slotName,
  name: "",
  projectedPoints: 0,
  salary: 0,
  ownership: 0,
  team: "",
  teamFullName: "",
  opposingTeam: "",
  isMlbStartingPitcher: false,
  isConfirmed: false,
  isExpected: false,
  isOut: false,
  isGtd: false,
  isDtd: false,
});

export const emptyPlayer: Api.Player = {
  id: "",
  aslBaseId: 0,
  headshotUrl: "",
  name: "",
  position: "",
  salary: 0,
  team: "",
  slotName: "",
  slotNumber: 0,
  projectedPoints: 0,
  ownership: 0,
  teamFullName: "",
  opposingTeam: "",
  isMlbStartingPitcher: false,
  isConfirmed: false,
  isExpected: false,
  isOut: false,
  isGtd: false,
  isDtd: false,
};

export const parseProjectionPoints = (
  projectedPoints: Record<string, number>): string => {
  if (projectedPoints.Quarter4 > 0) {
    return projectedPoints.Quarter4?.toFixed(1) || "0.0";
  }
  return Object.values(projectedPoints).find(points => points > 0)?.toFixed(1) || "0.0";
};

export const groupBy = <T, K extends keyof T>(array: T[], key: K): Record<string, T[]> => {
  return array.reduce((acc, item) => {
    const keyValue = item[key] as unknown as string;
    if (!acc[keyValue]) {
      acc[keyValue] = [];
    }
    acc[keyValue].push(item);
    return acc;
  }, {} as Record<string, T[]>);
};

export const dispatchFlipPayEvent = (eventName: "out_of_lineups" | "out_of_messages" | "no_subscription") => {
  document.dispatchEvent(new Event(eventName));
};

export const getPaymentRequiredErrorFromResponseIfExists = async (response: Response): Promise<string | undefined> => {
  if (response.ok) return undefined;
  if (response.status !== 402) return undefined;

  const body = await response.clone().json();

  return body["error"];
};

export const isInsufficientTokensResponse = async (response: Response): Promise<boolean> => {
  return (await getPaymentRequiredErrorFromResponseIfExists(response)) === "insufficient_tokens";
};

export const isMissingSubscriptionResponse = async (response: Response): Promise<boolean> => {
  return (await getPaymentRequiredErrorFromResponseIfExists(response)) === "subscription_required";
};

export const getElementAbsolutePosition = (element: HTMLElement) => {
  const rect = element.getBoundingClientRect();
  const scrollLeft = window.scrollX || document.documentElement.scrollLeft;
  const scrollTop = window.scrollY || document.documentElement.scrollTop;

  const absoluteLeft = rect.left + scrollLeft;
  const absoluteTop = rect.top + scrollTop;

  return { left: absoluteLeft, top: absoluteTop };
};

export const excludeNonStartersParticipants = (action: "add" | "remove", participants: Api.Participant[], excludedPlayers: string[] | undefined, setExcludedPlayers: (excludedPlayers: string[] | undefined) => void) => {
  const parsedNonStartersIds = participants.filter((participant) => participant.excludedInOnlyStartersMode).map((participant) => participant.id);
  return action === "add" ? setExcludedPlayers([...(excludedPlayers || []), ...parsedNonStartersIds]) : setExcludedPlayers((excludedPlayers || []).filter((playerId) => !parsedNonStartersIds.includes(playerId)));
}